import React from 'react';
import NavigationDesk from '../Components/NavigationDesk';
import Footer from '../Components/Footer';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';


const PageDetail = ({ pages }) => {
    const [activeTab, setActiveTab] = React.useState('features');
    const [activeServiceTabs, setActiveServiceTab] = React.useState('procedure');

    const { slug } = useParams();
    const [pageData, setPageData] = useState(null);

    const [activeFaq, setActiveFaq] = useState('');
    const [faqTab, setFaqTab] = useState(null);

    const activateTab = (e) => {
        setActiveTab(e.target.id);
        console.log(e.target.id);
    };

    const activateServiceTab = (e) => {
        setActiveServiceTab(e.target.id);
        console.log(e.target.id);
    };

    const activateFaqTab = (id) => {
        setFaqTab(id);
    };

    const toggleFaq = (id) => {
        setActiveFaq(activeFaq === id ? '' : id);
    };


    useEffect(() => {
        // Attempt to find the page in the passed pages prop
        const page = pages.find(p => decodeURIComponent(slug) === p.slug);
        if (page) {
            setPageData(page);
        } else {
            // If the page isn't found in the prop, fetch it individually
            const fetchPageData = async () => {
                const response = await fetch(`https://admin.cryon.studio/wp-json/wp/v2/pages?slug=${slug}`);
                const data = await response.json();
                if (data.length > 0) {
                    setPageData(data[0]); // Assuming the first match is the desired page
                }
            };
            fetchPageData();
        }
    }, [slug, pages]);

    if (!pageData) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <NavigationDesk />
            <h2 className='back-button cta'><a href="/">Назад</a></h2>
            <section className="services-section padding-for-main-banner">
                <h1 className="service-h1">{pageData.acf.headline}</h1>

                <div className="services-video-descr-img">
                    <div className="service-image-containter">
                        <img src={pageData.acf.imageService} />
                    </div>
                    <div className="service-video-descr">
                        <div className="service-description">
                            <p>{pageData.acf.description}</p>
                        </div>
                        <div className="service-youtube-video">
                            <iframe width="100%" height="450" src={pageData.acf.youtube} title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section">

                <div className="tab">
                    <button className={`tablinks ${activeTab === 'features' ? 'active' : ''}`} onClick={activateTab} id="features">Преимещуства</button>
                    <button className={`tablinks ${activeTab === 'positive' ? 'active' : ''}`} onClick={activateTab} id="positive">Показания</button>
                    <button className={`tablinks ${activeTab === 'negative' ? 'active' : ''}`} onClick={activateTab} id="negative">Противопоказания</button>
                </div>

                <div id="features" className={`tabcontent ${activeTab === 'features' ? 'activeTab' : ''}`}>
                    <div dangerouslySetInnerHTML={{ __html: pageData.acf.features }} />
                </div>

                <div id="positive" className={`tabcontent ${activeTab === 'positive' ? 'activeTab' : ''}`}>
                    <div dangerouslySetInnerHTML={{ __html: pageData.acf.positive }} />
                </div>

                <div id="negative" className={`tabcontent ${activeTab === 'negative' ? 'activeTab' : ''}`}>
                    <div dangerouslySetInnerHTML={{ __html: pageData.acf.negative }} />
                </div>

            </section>

            <section className="services-section">
                <div className="image-tabs">
                    <div className="service-image-containter">
                        <img src={pageData.acf.howToImage} />
                    </div>
                    <div>
                        <div className="tab">
                            <button className={`tablinks_1 ${activeServiceTabs === 'procedure' ? 'active' : ''}`} onClick={activateServiceTab} id="procedure">Как проходит процедура</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'result' ? 'active' : ''}`} onClick={activateServiceTab} id="result">Результат</button>
                            <button className={`tablinks_1 ${activeServiceTabs === 'beforeAfter' ? 'active' : ''}`} onClick={activateServiceTab} id="beforeAfter">До/После</button>
                        </div>
                        <div id="procedure" className={`tabcontent_1 ${activeServiceTabs === 'procedure' ? 'activeTab' : ''}`}>
                            <div dangerouslySetInnerHTML={{ __html: pageData.acf.procedure }} />
                        </div>

                        <div id="result" className={`tabcontent_1 ${activeServiceTabs === 'result' ? 'activeTab' : ''}`} >
                            <div dangerouslySetInnerHTML={{ __html: pageData.acf.results }} />
                        </div>  

                        <div id="beforeAfter" className={`tabcontent_1 ${activeServiceTabs === 'beforeAfter' ? 'activeTab' : ''}`}>
                            <div className="tabs-item">
                                <div className="beforeafter_container">
                                    <div className="beforeafter_images">
                                        <img src={pageData.acf.before} />
                                        <p>До</p>
                                    </div>
                                    <div className="beforeafter_images">
                                        <img src={pageData.acf.after} />
                                        <p>После</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>

            <section className='faq-section'>
                <div className='faq-container'>
                    <div>
                        <h2>Часто задаваемые вопросы</h2>
                    </div>

                    <div>
                        {pageData.acf?.faq && Object.entries(pageData.acf.faq)
                            .filter(([_, { q, a }]) => q && a) // Filter out entries where `q` or `a` is falsy
                            .map(([id, { q, a }]) => (
                                <React.Fragment key={id}>
                                    <div className='accordionContainer'>
                                        <button className="accordion" onClick={() => activateFaqTab(id)} id={id}>
                                            <span>{q}</span>
                                            <img src="./img/icons/faq-arrow.svg" style={faqTab === id ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' }} />
                                        </button>
                                        <div className="panel" style={faqTab === id ? { display: 'block' } : { display: 'none' }}>
                                        <div dangerouslySetInnerHTML={{ __html: a }}></div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                    </div>

                </div>
            </section>
            <section className='services-section'>

                <h2>Отлично сочетается:</h2>
                {pageData.acf?.perfectMatch && Object.entries(pageData.acf.perfectMatch)
                    .filter(([_, { serviceName, serviceLink }]) => serviceName && serviceLink) // Filter out entries where `q` or `a` is falsy
                    .map(([id, { serviceName, serviceLink }]) => (
                        <React.Fragment key={id}>
                            <div className='relevant-item'>
                                <h3>{serviceName}</h3>
                                <a href={`/`+ serviceLink} className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                            </div>
                        </React.Fragment>
                    ))}


                {/* <div className='relevant-item'>
                    <h3>EMSCULPT</h3>
                    <a href='/EMSCULPT' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>RF-лифтинг</h3>
                    <a href='/RFLifting' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div>
                <div className='relevant-item'>
                    <h3>Сферотерапия</h3>
                    <a href='/Spheretherapy' className='cta-relevant' target='_blank'>Подробнее о процедуре</a>
                </div> */}


            </section>

            {/* <h1>{pageData.title.rendered}</h1>
        <div dangerouslySetInnerHTML={{ __html: pageData.content.rendered }} /> */}
            {/* Render additional details as needed */}
            <Footer />
        </>
    );
};

export default PageDetail;