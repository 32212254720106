import React from "react";

const Footer = () => {

    return (
        <>
            <footer className="foot">
                <div className="footer-content">
                    <div className="footer-logo">
                        <img src="./img/logo.svg" />
                    </div>
                    <nav className="footer-nav">
                        <a href="#about-link">О нас</a>
                        <a href="#all_services">Услуги и цены</a>
                        <a href="#masters-link">Наши мастера</a>
                        {/* <a href="#tools-link">Оборудованиe</a> */}
                        <a href="#contacts-link">Адреса студий</a>
                    </nav>
                    <div className="footer-socials">
                        <p><small>Ищите нас в социальных сетях:</small></p>
                        <div className="socials-icons">
                            <div className="icon">
                                <a href="https://www.instagram.com/cryon__studio" target="_blank">
                                    <img src="./img/icons/instagram.svg" />
                                </a>
                            </div>
                            <div className="icon">
                                <a href="https://wa.me/79956556564?text=%D0%9D%D0%B0%D1%88%D0%BB%D0%B0%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8E%20%D0%BD%D0%B0%20%D0%B2%D0%B0%D1%88%D0%B5%D0%BC%20%D1%81%D0%B0%D0%B9%D1%82%D0%B5%2C%20%D1%83%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D1%82%D0%B5%20%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0%20-%20" target="_blank">
                                    <img src="./img/icons/whatsapp.svg" />
                                </a>
                            </div>
                            <div className="icon telegram">
                                <a href="https://vk.com/cryonstudio" target="_blank">
                                    <img src="./img/icons/vk.svg" />
                                </a>
                            </div>
                            <div className="icon telegram">
                                <a href="https://www.youtube.com/@cryon_studio" target="_blank">
                                    <img src="./img/icons/youtube.svg" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-address">
                        <p>ул. Старопетровский пр. дом 8</p>
                        <p>м. Войковская</p>
                        <a href="tel:+7 (495) 157-65-65"><p>+7 (495) 157-65-65</p></a>
                        <p>Пн.-Вс. с 10:00 - 22:00</p>
                    </div>
                </div>
                <div className="disclaimer">Работаем только по предварительной записи!</div>

                <div className="yandex-badges">
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/242483318465?type=rating" width="150" height="70" frameborder="0"></iframe>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/242483318465?type=award" width="150" height="70" frameborder="0"></iframe>
                    <iframe src="https://yandex.ru/sprav/widget/rating-badge/242483318465?type=alt" width="150" height="70" frameborder="0"></iframe>
                </div>

            </footer>
        </>
    );
};

export default Footer;