
import './App.css';


import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './Components/Main';
import PageDetail from './Components/PageDetail';

// function App() {

//   return (
//     <div className="App">
//       <Router>
//         <Routes>
//           <Route path="/" element={<Main />} />
//           <Route path="/EMSCULPT" element={<EMSCULPT />} />
//           <Route path="/Liposonix" element={<Liposonix />} />
//           <Route path="/LPG" element={<LPG />} />
//           <Route path="/RFLifting" element={<RFLifting />} />
//           <Route path="/Cryolipoliz" element={<Cryolipoliz />} />
//           <Route path="/Presstherapy" element={<Presstherapy />} />
//           <Route path="/Cavitation" element={<Cavitation />} />
//           <Route path="/Covering" element={<Covering />} />
//           <Route path="/LaserEpil" element={<LaserEpil />} />
//           <Route path="/Massage" element={<Massage />} />
//           <Route path="/Spheretherapy" element={<Spheretherapy/>} />
//           <Route path="/Abonement" element={<Abonement />} />
          
//         </Routes>
//       </Router>
//     </div>
//   );
// }

// export default App;

const App = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://admin.cryon.studio/wp-json/wp/v2/pages');
      const data = await response.json();
      setPages(data);
    };

    fetchData();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        {/* Dynamic route for page details */}
        <Route path="/:slug" element={<PageDetail pages={pages} />} />
      </Routes>
    </Router>
  );
};

export default App;
