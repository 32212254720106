import React from "react"; 
import { useEffect } from "react";


const Reviews = () => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.reviewlab.ru/widget/index-es2015.js";
        script.defer = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

    return (
        <section className="reviews-section" id="reviews-link">
            <div className="image-with-h2 tools-header-margin">
                <img className="header-img reviews-img-header" style={{ zIndex: -1 }} src="./img/reviews.svg" />
                <h2>Отзывы</h2>
            </div>
            <review-lab data-widgetid="646cab85c5b33598f7f8d848"></review-lab>

           
        </section>
    );
};

export default Reviews;